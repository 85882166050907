export const DATA_TUTORIAL_DOUBTS = [
  {
    id: 0,
    title: 'Olá, vestibulando(a). Tudo bem com você?',
    text: '<p>Antes de começar, gostaríamos de explicar como funciona o nosso tira-dúvidas.</p>',
  },
  {
    id: 1,
    title: 'Sobre as dúvidas',
    text: '<Elas>Além das suas dúvidas, aqui você pode encontrar <strong>dúvidas dos outros alunos do Explicaê e ainda respondê-las. </strong> Elas também podem ser <strong>filtradas de acordo com a matéria e o assunto.</strong></p>',
    imgs: [
      {
        src: 'about-doubts.svg',
        alt: 'A imagem mostra a seção das dúvidas enviadas pelos usuários.',
      },
    ],
  },
  {
    id: 2,
    title: 'Enviando a sua dúvida',
    text: '<p>Para enviar sua dúvida é muito simples. Primeiro <strong>você deverá escolher a matéria que a sua dúvida pertence </strong>e se quiser você também pode optar por escolher qual o assunto dessa matéria a sua dúvida se refere.</p></br><p>No final você só precisa digitar a sua dúvida e enviar.</p>',
    imgs: [
      {
        src: 'send-doubts.svg',
        alt: 'A imagem mostra a seção para enviar uma dúvida',
      },
    ],
  },
  {
    id: 3,
    title: 'Créditos para envio de dúvidas',
    text: '<p>Todo mês você terá <strong>uma quantidade de créditos disponíveis</strong> para o envio da sua dúvida, que varia de acordo com seu plano e pode ser visualizada na página inicial de dúvida.</p></br><p><strong>A cada envio de dúvida um crédito será consumido.</strong> E após consumir todos os créditos você só poderá enviar uma nova dúvida no mês seguinte quando <strong>os créditos são renovados.</strong></p>',
    imgs: [
      {
        src: 'credits-doubts.svg',
        alt: 'A imagem mostra a seção a qual apresenta a quantidade de créditos disponíveis para o envio de dúvida no mês.',
      },
    ],
  },
  {
    id: 4,
    title: 'Acessando a sua dúvida',
    text: '<p>Uma vez a dúvida enviada, você poderá acessá-la em “minhas dúvidas”.</p>',
    imgs: [
      {
        src: 'my-doubts.svg',
        alt: 'A imagem mostra a seção das dúvidas enviadas pelo usuários.',
      },
    ],
  },
  {
    id: 5,
    title: 'Conheceu nosso tira-dúvidas?',
    text: '<p>Agora que já sabe tudo o que precisa saber sobre o nosso tira-dúvidas. Vamos começar a enviar suas dúvidas?</p>',
  },
];
